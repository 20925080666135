import { gql } from 'graphql-request';

export const ProfileFragment = gql`
  fragment Profile on User {
    id
    name
    tier
    status
    avatar
    email
    campaign
    identityStrategies
    preferredPlatform
    rolePermissions {
      owner
      editor
      viewer
      public
    }
    settings {
      name
      value
    }
  }
`;
