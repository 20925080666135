import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import type { AuthResult } from '@/components/auth/types';
import type { LaunchOption } from '@/hooks/useLauncher';
import type { Action, OpenArgs } from './types';

type State = {
  isOpen: boolean;
  action: Action | undefined;
  style: 'alert' | 'inline' | undefined;
  launch: LaunchOption | undefined;
  defaults: OpenArgs['defaults'];
  open: (args?: OpenArgs) => void;
  close: () => void;
  onCancel: (() => void) | undefined;
  onAuthenticated: ((result: AuthResult) => void) | undefined;
};

export const useRegisterModalStore = createWithEqualityFn<State>()(
  (set) => ({
    isOpen: false,
    action: undefined,
    style: undefined,
    launch: undefined,
    defaults: {},
    onCancel: undefined,
    onAuthenticated: undefined,
    open: (args?: OpenArgs) =>
      set({
        action: args?.action,
        launch: args?.launch,
        style: args?.style,
        defaults: args?.defaults || {},
        onAuthenticated: args?.onAuthenticated,
        onCancel: args?.onCancel,
        isOpen: true,
      }),
    close: () => set({ isOpen: false }),
  }),
  shallow
);

export const useOpenRegisterModal = () => {
  const open = useRegisterModalStore((state) => state.open);
  return { openRegisterModal: open };
};
