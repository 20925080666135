import type { DateTime } from 'luxon';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import type { PlatformTypes } from '@/types/gql.generated';

type EventLike = {
  id: string;
  startDate: DateTime;
};

export type EntryExport = {
  entry: EventLike;
  externalLink: string;
  /** Indicates that the entry has been updated after it was exported */
  isStale: boolean;
  platform: PlatformTypes;
};

export type PlatformExport = {
  platform: PlatformTypes;
  integrationId: string;
  calendarId: string;
  entryExports: EntryExport[];
};

type State = {
  addExport: (item: PlatformExport) => void;
  invalidateEntryExport: (entry: EventLike) => void;
  platformExports: PlatformExport[];
  reset: () => void;
};

const getStartDate = (entry: EventLike): Date => {
  return entry.startDate.toJSDate();
};

export const usePlatformExports = createWithEqualityFn<State>()(
  (set) => ({
    platformExports: [],
    addExport: (platformExport) => {
      set((state) => {
        const existing = state.platformExports.find((item) => {
          return (
            item.platform === platformExport.platform &&
            item.integrationId === platformExport.integrationId &&
            item.calendarId === platformExport.calendarId
          );
        });

        if (!existing) {
          return {
            platformExports: [...state.platformExports, platformExport],
          };
        }

        const newEntryExports = platformExport.entryExports.filter(
          (item) =>
            !existing.entryExports.some(
              (existingItem) => existingItem.entry.id === item.entry.id
            )
        );

        return {
          platformExports: state.platformExports.map((item) => {
            if (item.calendarId !== existing.calendarId) {
              return item;
            }

            return {
              ...platformExport,
              entryExports: [...existing.entryExports, ...newEntryExports]
                .sort((a, b) => {
                  const aStartAt = getStartDate(a.entry);
                  const bStartAt = getStartDate(b.entry);

                  return aStartAt > bStartAt ? 1 : -1;
                })
                .map((entryExport) => {
                  return {
                    ...entryExport,
                    isStale: false,
                  };
                }),
            };
          }),
        };
      });
    },
    invalidateEntryExport: (entry) => {
      set((state) => {
        const updatedPlatformExports = state.platformExports.map(
          (platformExport) => {
            return {
              ...platformExport,
              entryExports: platformExport.entryExports.map((item) => {
                if (item.entry.id === entry.id) {
                  return {
                    ...item,
                    isStale: true,
                  };
                }
                return item;
              }),
            };
          }
        );

        return {
          platformExports: updatedPlatformExports,
        };
      });
    },
    reset: () => set({ platformExports: [] }),
  }),
  shallow
);
