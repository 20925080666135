import { DateTime } from 'luxon';

export const isValidDateString = (value: unknown): value is string => {
  if (typeof value !== 'string') {
    return false;
  }
  try {
    const parsed = DateTime.fromISO(value);
    return parsed.isValid;
  } catch (err) {
    return false;
  }
};
