import { type ReactNode, useEffect } from 'react';
import { config } from '@/config';
import { useAnalytics } from '@/hooks/useAnalytics';
import { Sentry } from '@/lib';
import { setUser } from '@/lib/logger';
import { CurrentUserContext } from './CurrentUserContext';
import { useProfile } from './hooks';

type Props = {
  children: ReactNode;
};

// This context mainly acts as a proxy to the useProfile hook while providing
// a logical space to perform one-time side-effects, like initializing LogRocket.
// If we didn't have side-effects then components could just use the useProfile
// hook directly.
export const CurrentUserContextProvider = ({ children }: Props) => {
  const profile = useProfile();
  const { identify: identifyAnalytics } = useAnalytics();
  const { currentUser } = profile;

  useEffect(() => {
    if (config.sentry.enabled) {
      const user = currentUser
        ? {
            ...currentUser,
            email: currentUser.email ?? undefined,
          }
        : null;
      Sentry.setUser(user);
    }

    if (currentUser) {
      identifyAnalytics(currentUser);
      setUser(currentUser);
    }
  }, [currentUser, identifyAnalytics]);

  return (
    <CurrentUserContext.Provider value={profile}>
      {children}
    </CurrentUserContext.Provider>
  );
};
