import { browserTracingIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { config } from '@/config';
import { getClientName } from '@/utils/getClientName';

Sentry.init({
  enabled: config.sentry.enabled,
  environment: config.env,
  dsn: config.sentry.dsn,
  integrations: [browserTracingIntegration()],
  tracesSampleRate: 0.2,
  normalizeDepth: 10,
  maxValueLength: 10_000,
  allowUrls: ['https://agendahero.com', 'https://server.dev'],
  release: config.release,
  beforeSend: (event) => {
    if (event.exception && event.exception.values) {
      const stacktrace = event.exception.values[0].stacktrace;

      if (stacktrace && stacktrace.frames) {
        const isExternalError = stacktrace.frames.every((frame) => {
          // Check if the frame is from an external source
          const filename = frame.filename || '';
          return (
            // Filter out browser extensions
            filename.startsWith('chrome-extension://') ||
            filename.startsWith('moz-extension://') ||
            // Filter out other external scripts
            (filename.includes('://') &&
              !filename.includes(window.location.hostname)) ||
            // Filter out eval/anonymous scripts
            filename === '<anonymous>' ||
            filename.includes('eval')
          );
        });

        if (isExternalError) {
          return null;
        }
      }
    }

    return event;
  },
  ignoreErrors: [
    'Importing a module script failed',
    'Failed to fetch dynamically imported module',
    "Can't find variable: BroadcastChannel",
    'Websocket closed: 4004, Bad response',
    'Promise.withResolvers is not a function',
    "Failed to read the 'localStorage' property from 'Window'",
    "Failed to read the 'sessionStorage' property from 'Window'",
    'Network request failed',
    "Failed to execute 'transaction' on 'IDBDatabase'", // IndexDB not supported
  ],
  initialScope: (scope) => {
    scope.setTags({
      version: config.version,
      client: getClientName(),
    });
    return scope;
  },
});

export { Sentry };
