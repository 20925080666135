export const defaultFeatureValues = {
  requiredVersion: '0.0.0',
  scheduleDescriptionEnabled: false,
  snippetsEnabled: false,
  rsvpEnabled: false,
  copyScheduleEnabled: false,
  quickCreateMaintenanceModeEnabled: false,
  disableAllAiFeatures: false,
  conflictScheduleEnabled: false,
  bannerMessage: '',
  bannerLink: '',
  updateScheduleMetadataEnabled: false,
  agendaHeroScheduleAsSource: false,
  magicInviteEnabled: false,
  magicResultsInPlatformEnabled: false,
  experiencesEnabled: false,
  experiencesLayoutTabEnabled: false,
  experiencesCustomColorEnabled: false,
  experiencesColorWheelEnabled: true,
};

export type FeatureFlag = keyof typeof defaultFeatureValues;
