import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { usePlatformExports } from '@/pages/QuickCreate/hooks/usePlatformExports';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import { getEventLink } from '@/pages/QuickCreate/utils/googleCalendar';
import { type ExportEventsInput, PlatformTypes } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type { Destination } from '../types';
import type {
  ExportMagicEventsMutation,
  ExportMagicEventsMutationVariables,
} from './useExportMagicEvents.generated';

const query = gql`
  mutation ExportMagicEvents($input: ExportEventsInput!) {
    exportEvents(input: $input) {
      id
      calendarId
      eventId
      externalId
      externalLink
    }
  }
`;

export const useExportMagicEvents = () => {
  const { addExport } = usePlatformExports();
  const { mutate, ...rest } = useMutation<
    ExportMagicEventsMutation,
    QueryError,
    ExportMagicEventsMutationVariables
  >({
    mutationFn: (variables) => gqlClient.request(query, variables),
  });

  const exportMagicEvents = useCallback(
    (
      destination: Destination,
      magicEvents: DecoratedMagicEvent[],
      attendees: string[],
      mutateOptions?: MutateOptions<
        ExportMagicEventsMutation,
        QueryError,
        ExportMagicEventsMutationVariables
      >
    ) => {
      const input: ExportEventsInput = {
        integrationId: destination.integration.id,
        eventIds: magicEvents.map((item) => item.id),
        attendees,
        calendarId: destination.calendar.id,
      };

      return mutate(
        { input },
        {
          ...mutateOptions,
          onSuccess: (result, ...reset) => {
            const entryLookup = magicEvents.reduce<
              Record<string, DecoratedMagicEvent>
            >((lookup, entry) => {
              return { ...lookup, [entry.id]: entry };
            }, {});

            addExport({
              platform: PlatformTypes.GoogleIntegration,
              integrationId: destination.integration.id,
              calendarId: destination.calendar.id,
              entryExports: result.exportEvents.map((item) => {
                const entry =
                  (item.eventId && entryLookup[item.eventId]) || null;

                if (!entry) {
                  throw new Error(`MagicEvent not found for export`);
                }

                return {
                  entry,
                  externalLink: getEventLink(destination.integration, item),
                  isStale: false,
                  platform: PlatformTypes.GoogleIntegration,
                };
              }),
            });

            mutateOptions?.onSuccess?.(result, ...reset);
          },
        }
      );
    },
    [mutate, addExport]
  );
  return { exportMagicEvents, ...rest };
};
