import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  createExperienceQueryKey,
  createExperiencesQueryKey,
  createLibraryQueryKey,
  createSchedulesQueryKey,
} from '@/utils/queryKeys';

export const useRevalidate = () => {
  const queryClient = useQueryClient();

  const revalidate = useCallback(() => {
    // Invalidate any schedules that exist in cache since the user might
    // have new permissions post-authentication
    queryClient.invalidateQueries({ queryKey: createSchedulesQueryKey() });
    queryClient.invalidateQueries({ queryKey: createLibraryQueryKey() });
    queryClient.invalidateQueries({ queryKey: createExperiencesQueryKey() });

    const experienceQueryPrefix = createExperienceQueryKey('id')[0];
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === experienceQueryPrefix,
    });
  }, [queryClient]);

  return { revalidate };
};
