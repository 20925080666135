import { DateTime } from 'luxon';
import { Frequency, RRule } from 'rrule';
import ENGLISH from 'rrule/dist/esm/nlp/i18n';
import { TIME_ZONE_UTC } from '@/constants';
import { isMultiDay } from '@/utils/dates';

const defaultDateFormat = 'ccc, MMM d';
const defaultDateFormatWithYear = 'ccc, MMM d, y';

type Options = {
  dateFormat?: string;
  dateFormatWithYear?: string;
  isUpcoming?: boolean;
  displayTimezone?: string;
};

const getMultiDayDateSummary = (
  startDate: DateTime,
  endDate: DateTime,
  isOnDay: boolean
): string => {
  const isSameYear = endDate.hasSame(startDate, 'year');
  const startDateFormat = isSameYear
    ? defaultDateFormat
    : defaultDateFormatWithYear;
  const endDateFormat = defaultDateFormatWithYear;

  if (isOnDay) {
    const start = startDate.toFormat(startDateFormat);
    const end = endDate.minus({ day: 1 }).toFormat(endDateFormat);
    return `${start} - ${end}, all day`;
  }

  const toFormat = (date: DateTime, format: string): string => {
    return date
      .toFormat(`${format} 'at' h:mm a`)
      .replace(/AM|PM/g, ($1) => $1.toLowerCase())
      .replace(/:00/g, '');
  };

  const start = toFormat(startDate, startDateFormat);
  const end = toFormat(endDate, endDateFormat);

  return `${start} - ${end}`;
};

// date objects in rrule.js are always in UTC so parse them as such,
// then convert back to the zone that the schedule is being displayed in
const fromJSDate = (date: Date, timeZone: string) =>
  DateTime.fromJSDate(date, { zone: 'UTC' }).setZone(timeZone);
const fromFormat = (date: string, format: string, timeZone: string) =>
  DateTime.fromFormat(date, format, { zone: 'UTC' }).setZone(timeZone);

const getTimeSummary = (
  startDate: DateTime,
  endDate: DateTime,
  isOnDay: boolean
) => {
  if (isOnDay) {
    return 'all day';
  }
  let startTimeFormat = 'h:mm';
  if (startDate.hour < 12 !== endDate.hour < 12) {
    startTimeFormat = 'h:mm a';
  }

  let time = `${startDate.toFormat(startTimeFormat)} - ${endDate.toFormat(
    'h:mm a'
  )}`;
  time = time.replace(/:00/g, '');
  time = time.toLowerCase();
  return time;
};

export const getDateSummary = (
  startDate: DateTime,
  endDate: DateTime,
  isOnDay: boolean,
  rule?: string | null,
  options?: Options
): string => {
  if (isMultiDay(startDate, endDate)) {
    return getMultiDayDateSummary(startDate, endDate, isOnDay);
  }

  const dateFormat = options?.dateFormat ?? defaultDateFormat;
  const dateFormatWithYear =
    options?.dateFormatWithYear ?? defaultDateFormatWithYear;

  const time = getTimeSummary(startDate, endDate, isOnDay);
  const timeZone = startDate.zoneName;
  let str = '';

  if (rule) {
    const rrule = RRule.fromString(rule);
    const { freq, until, byweekday, bynweekday } = rrule.options;
    const today = DateTime.local({
      zone: options?.displayTimezone ?? timeZone,
    }).startOf('day');
    const isStartDateHidden = Boolean(options?.isUpcoming && startDate < today);

    // optimize for daily recurrence disguised as weekly
    if (freq === Frequency.WEEKLY && byweekday.length === 7) {
      if (!isStartDateHidden) {
        const startStr = startDate.toFormat(dateFormatWithYear);
        str = `every day starting ${startStr}`;
      }

      if (until) {
        const untilStr = fromJSDate(
          until,
          isOnDay ? TIME_ZONE_UTC : timeZone
        ).toFormat(dateFormat);
        str = `${str} until ${untilStr}`;
      }

      return `${str}, ${time}`;
    }

    str = rrule.toText(
      (text) => {
        if (typeof text !== 'string') {
          return String(text);
        }
        // "Every day starting X"
        if (!until && ['day', 'year'].includes(text)) {
          return `${text} starting ${startDate.toFormat(dateFormatWithYear)}`;
        }
        // "Every day from X to Y"
        if (text === 'until') {
          if (!isStartDateHidden) {
            return `from ${startDate.toFormat(dateFormat)} to`;
          } else {
            return 'until';
          }
        }
        return text;
      },
      {
        ...ENGLISH,
        dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      },
      (year, month, day) => {
        return fromFormat(
          [month, day, year].join(' '),
          'MMMM d y',
          isOnDay ? TIME_ZONE_UTC : timeZone
        ).toFormat(dateFormatWithYear);
      }
    );

    if (freq === Frequency.WEEKLY && !until && !isStartDateHidden) {
      const start = startDate.toFormat(dateFormatWithYear);
      str = `${str} starting ${start}`;
    }

    // Monthly recurring on a specific day
    if (freq === Frequency.MONTHLY && !until && !bynweekday) {
      str = `${str} on day ${startDate.day}`;
    }
  } else {
    str = startDate.toFormat(dateFormatWithYear);
  }

  str = `${str}, ${time}`;

  return str;
};
