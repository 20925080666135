import { cssVar, type ComponentStyleConfig } from '@/ui';

const $tooltipArrowBg = cssVar('popper-arrow-bg');

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    bg: 'customgray.dark',
    color: 'customgray.light',
    [$tooltipArrowBg.variable]: 'colors.customgray.dark',
  },
  variants: {
    light: {
      bg: 'white',
      color: 'customgray.mid',
      lineHeight: 1.4,
      padding: 3,
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
      [$tooltipArrowBg.variable]: 'white',
    },
    simple: {
      bg: 'white',
      color: 'dark.800',
      lineHeight: 1.5,
      padding: '4px 8px',
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
      [$tooltipArrowBg.variable]: 'white',
    },
  },
};
