import { createDecoratedMagicEvent } from '@/features/events/lib/createDecoratedMagicEvent';
import type { MagicJobFragment } from '../fragments/MagicJobFragment.generated';
import type { DecoratedMagicJob } from '../types';

export const createDecoratedMagicJob = (
  job: MagicJobFragment
): DecoratedMagicJob => {
  return {
    ...job,
    events: job.events.map(createDecoratedMagicEvent),
  };
};
