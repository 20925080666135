import { gql } from 'graphql-request';
import { useCallback, useState } from 'react';
import { gqlClient, queryClient, Sentry } from '@/lib';
import { isGQLError } from '@/utils/errors';
import { createAuthActionQueryKey } from '@/utils/queryKeys';
import type {
  GetAuthActionQuery,
  GetAuthActionQueryVariables,
} from './useAuthAction.generated';

const query = gql`
  query GetAuthAction($email: LowercaseString!) {
    getAuthAction(email: $email)
  }
`;

export const useAuthAction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [authActionError, setAuthActionError] = useState<Error | null>(null);

  const getAuthAction = useCallback(async (email: string) => {
    setIsLoading(true);
    setAuthActionError(null);

    try {
      const result = await queryClient.fetchQuery({
        queryKey: createAuthActionQueryKey(email),
        queryFn: async () => {
          const result = await gqlClient.request<
            GetAuthActionQuery,
            GetAuthActionQueryVariables
          >(query, { email });

          return result.getAuthAction;
        },
      });

      setIsLoading(false);

      return result;
    } catch (error) {
      setIsLoading(false);

      if (isGQLError(error)) {
        setAuthActionError(error);
      } else {
        Sentry.captureException(error);
      }
      return null;
    }
  }, []);

  return {
    isLoading,
    authActionError,
    getAuthAction,
  };
};
