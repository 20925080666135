import { Alert, AlertIcon, AlertTitle } from '@/ui';
import {
  getErrorMessage,
  isGQLErrorOfType,
  type QueryError,
} from '@/utils/errors';
import { getAuthErrorMessage } from './auth/utils';

type Props = {
  authError: QueryError;
};

export const AuthError = ({ authError }: Props): JSX.Element => {
  const validationError = isGQLErrorOfType(
    authError,
    'MerValidationErrFailedValidation'
  );
  const serverErrorMsg = validationError
    ? getAuthErrorMessage(authError)
    : getErrorMessage(authError);

  return (
    <Alert status={validationError ? 'warning' : 'error'}>
      <AlertIcon />
      <AlertTitle>{serverErrorMsg}</AlertTitle>
    </Alert>
  );
};
