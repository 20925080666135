import type { ChakraProps } from '@/ui';

export const textStyles: Record<string, ChakraProps> = {
  magic: {
    bgClip: 'text',
    bgGradient: 'linear-gradient(to right, #685EDB, #D5257C)',
  },
  secondary: {
    fontSize: '14px',
    color: 'customgray.mid',
  },
};
