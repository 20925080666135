import { i18n } from '@/i18n';
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  type FlexProps,
  Heading,
  Image,
} from '@/ui';
import type { Action } from '../types';
import heart from './heart.png';

type Props = FlexProps & {
  action?: Action;
  variant?: 'alert' | 'inline';
};

function getIcon(action: Action): React.ReactElement | null {
  switch (action) {
    case 'save_page':
      return <Image src={heart} w={{ base: '50px', md: '65px' }} />;
    default:
      return null;
  }
}

function getDescription(action: Action): string {
  const t = i18n.getFixedT(null, 'auth', 'register_modal_actions');

  return {
    follow_schedule: t('follow_schedule'),
    follow_item: t('follow_item'),
    rsvp_item: t('rsvp_item'),
    copy_public_schedule: t('copy_public_schedule'),
    save_page: t('save_page'),
  }[action];
}

export const ActionText = ({
  action,
  variant = 'alert',
  ...props
}: Props): JSX.Element | null => {
  if (!action) {
    return null;
  }

  const description = getDescription(action);
  const icon = getIcon(action);

  if (variant === 'inline') {
    return (
      <Flex direction="column" gap="6" textAlign="center" {...props}>
        {icon && <Box mx="auto">{getIcon(action)}</Box>}
        {description && <Heading fontSize="2xl">{description}</Heading>}
      </Flex>
    );
  }

  return (
    <Alert status="info">
      <AlertIcon />
      {description}
    </Alert>
  );
};
