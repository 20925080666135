import { DateTime } from 'luxon';
import { TIME_ZONE_UTC } from '@/constants';
import type { MagicEventFragment } from '@/pages/QuickCreate/fragments/MagicEventFragment.generated';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import { createEntryDate, getLocalTimeZone } from '@/utils/dates';

type Timing = MagicEventFragment['start'];

const calcStartDate = (start: Timing, isAllDay: boolean): DateTime<true> => {
  const localTimeZone = getLocalTimeZone();
  const timezone = start.timeZone ?? localTimeZone;

  if (isAllDay) {
    const startString = start.date ?? DateTime.now().toISODate();
    return createEntryDate(startString, timezone, localTimeZone, true);
  }

  const startString =
    start.dateTime ?? DateTime.now().plus({ hour: 1 }).startOf('hour').toISO();
  return createEntryDate(startString, timezone, localTimeZone, false);
};

const calcEndDate = (
  end: Timing,
  isAllDay: boolean,
  start: DateTime<true>
): DateTime<true> => {
  const localTimeZone = getLocalTimeZone();
  const timezone = end.timeZone ?? localTimeZone;

  if (isAllDay) {
    const endString = end.date ?? start.toISO();
    return createEntryDate(endString, timezone, localTimeZone, true);
  }

  const endString = end.dateTime ?? start.plus({ hour: 1 }).toISO();
  return createEntryDate(endString, timezone, localTimeZone, false);
};

export const createDecoratedMagicEvent = (
  rawEvent: MagicEventFragment
): DecoratedMagicEvent => {
  const localTimeZone = getLocalTimeZone();
  const startDate = calcStartDate(rawEvent.start, rawEvent.isAllDay);

  return {
    ...rawEvent,
    timeZone: rawEvent.isAllDay
      ? TIME_ZONE_UTC
      : (rawEvent.start.timeZone ?? localTimeZone),
    startDate,
    endDate: calcEndDate(rawEvent.end, rawEvent.isAllDay, startDate),
  };
};
