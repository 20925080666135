import { Outlet } from 'react-router-dom';
import { Flex } from '@/ui';
import { SuspenseContainer } from '../SuspenseContainer';
import { Background } from './components/Background';
import { Header } from './components/Header';
import { usePreloadAssets } from './hooks/usePreloadAssets';

export const MagicTabsLayout = () => {
  usePreloadAssets();

  return (
    <>
      <Background />

      <Flex color="customgray.textdark" direction="column" flex="1">
        <Header />
        <SuspenseContainer>
          <Outlet />
        </SuspenseContainer>
      </Flex>
    </>
  );
};
